/* eslint-disable max-len */
import React from 'react';
import { NavLink } from 'react-router-dom';

const Navlinks = () => (
  <div className="flex flex-grow md:space-x-4 md:ml-2 xl:ml-24 flex-col md:flex-row md:px-0 px-4 border-t md:border-t-0 md:py-0 py-4">
    <NavLink
      to="/dashboard/home"
      activeClassName="active-menu"
      className=" text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal"
    >
      <span>Home</span>
    </NavLink>
    {/* <a href="/" className="text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal">Statistics</a> */}
    {/* <a href="#" className="text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal">Users</a>
                                <a href="#" className="text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal">Locations</a> */}
    <NavLink
      to="/dashboard/Devices"
      activeClassName="active-menu"
      className=" text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal"
    >
      <span>Devices</span>
    </NavLink>
    <NavLink
      to="/dashboard/Clients"
      activeClassName="active-menu"
      className=" text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal"
    >
      <span>Clients</span>
    </NavLink>
    <NavLink
      to="/dashboard/Users"
      activeClassName="active-menu"
      className=" text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal"
    >
      <span>Super Users</span>
    </NavLink>

    {/* <a href="/dashboard/clients" className="text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal">Clients</a>
                <a href="/dashboard/Users" className="text-color-title transition-colors px-3 py-2 rounded-md text-base font-normal">Users</a> */}
  </div>
);

export default Navlinks;
