/* eslint arrow-body-style: ["error", "always"] */
/* eslint object-curly-newline: ["error", { "multiline": true }] */
/* eslint-env es6 */
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import './signUp.scss';
import LoginLayout from '../../components/layout/loginLayout';
import authSlice from '../../redux/slices/auth.slice';
import LayoutAuth from '../../components/layout/layoutAuth';
import { loginFormSchema } from '../../services/validation';

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
};

const signUp = () => {
  const domId = 'emailInput';
  const [pwdVisibility, setPwdVisibility] = useState(false);
  const [cfmPwdVisibility, setCfmPwdVisibility] = useState(false);
  const [errorText, setErrorText] = useState('');
  // const [emailValue, setEmail] = useState('');
  // const [passwordValue, setPassword] = useState('');
  // const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const formikRef: any = useRef();
  const onSubmit = (values: typeof initialValues) => {
    if (values.password === values.confirmPassword) {
      const data = {
        email: values.email,
        password: values.password,
        confirmationToken:
          foo == null ? '' : foo,
      };
      dispatch(authSlice.actions.signUp(data));
    } else {
      setErrorText('password does not match');
    }
  };
  // const search = props.location.search;
  const params = new URLSearchParams(useLocation().search);
  const foo = params.get('token');
  // setToken(foo);

  return (
    <LayoutAuth>
      <LoginLayout>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={loginFormSchema}
        >
          {({ handleChange, handleSubmit, values, errors, touched }) => {
            return (
              <div className="mt-30 py-12 px-2 mx-auto max-w-sm w-full login-frm">
                <p className="text-2xl md:text-3xl font-bold text-black mb-6">
                  Sign in with your
                  <br />
                  Hygotech credentials
                </p>
                <form className="frm-container w-full">
                  <div className="flex flex-col mb-4">
                    <label
                      htmlFor={domId}
                      className="font-medium text-black mb-1"
                    >
                      Email
                      <div className="w-full relative">
                        <input
                          id={domId}
                          className="w-full text-sm px-4 pl-11 h-14 border-gray border border-gray-300 rounded-xl focus:outline-none placeholder-gray-300"
                          placeholder="Enter your Email"
                          type="email"
                          name="email"
                          onChange={handleChange}
                        />
                        <div className="absolute w-11 h-full top-0 left-0 email-icon" />
                      </div>
                    </label>
                    {errors.email && touched.email ? (
                      <div className="mt-1 text-sm font-medium text-red-400">
                        {errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={domId}
                      className="font-medium text-black mb-1"
                    >
                      Password
                      <div className="w-full relative">
                        <input
                          id={domId}
                          name="password"
                          onChange={handleChange}
                          className="w-full text-sm px-11 h-14 border-gray border border-gray-300 rounded-xl focus:outline-none placeholder-gray-300"
                          placeholder="Your password"
                          type={!pwdVisibility ? 'password' : 'text'}
                        />
                        <div className="absolute w-11 h-full top-0 left-0 pwd-icon" />
                        <div
                          onClick={() => {
                            setPwdVisibility(!pwdVisibility);
                          }}
                          aria-hidden="true"
                          className={`visible-icon absolute h-full top-0 right-0 ${
                            pwdVisibility ? 'active-icon' : ''
                          }`}
                        />
                      </div>
                      {errors.password && touched.password ? (
                        <div className="mt-1 text-sm font-medium text-red-400">
                          {errors.password}
                        </div>
                      ) : null}
                    </label>
                  </div>
                  <div className="flex flex-col mb-5">
                    <label
                      htmlFor={domId}
                      className="font-medium text-black mb-1"
                    >
                      Confirm password
                      <div className="w-full relative">
                        <input
                          id={domId}
                          name="confirmPassword"
                          onChange={handleChange}
                          className="w-full text-sm px-11 h-14 border-gray border border-gray-300 rounded-xl focus:outline-none placeholder-gray-300"
                          placeholder="Your password"
                          type={!cfmPwdVisibility ? 'password' : 'text'}
                        />
                        <div className="absolute w-11 h-full top-0 left-0 pwd-icon" />
                        <div
                          onClick={() => {
                            setCfmPwdVisibility(!cfmPwdVisibility);
                          }}
                          aria-hidden="true"
                          className={`visible-icon absolute h-full top-0 right-0 ${
                            cfmPwdVisibility ? 'active-icon' : ''
                          }`}
                        />
                      </div>
                      {errorText ? (
                        <div className="mt-1 text-sm font-medium text-red-400">
                          {errorText}
                        </div>
                      ) : null}
                    </label>
                  </div>
                  <div className="flex button-wrapper">
                    <button
                      onClick={() => {
                        if (values.email !== '' && values.password !== '') {
                          handleSubmit();
                        }
                      }}
                      type="button"
                      className="ml-2 rounded-3xl bg-primary text-white h-10 focus:outline-none mt-2 text-sm flex-1"
                    >
                      Sign Up
                    </button>
                  </div>
                  <div className="flex justify-end">
                    <p className="font-normal text-xs mt-6 text-black underline">
                      Forgot Password?
                    </p>
                  </div>
                </form>
              </div>
            );
          }}
        </Formik>
      </LoginLayout>
    </LayoutAuth>
  );
};

export default signUp;
