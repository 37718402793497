/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* createClient(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/createClient`, data);
}

function* logInAsClient(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/LogInAsClient`, data);
}

function* getAllClients(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getAllClients`, {
    params: {
      limit: values.limit,
      pageNo: values.pageNo,
      search: values.search,
    },
  });
}

function* resendInviteForClientAdmin(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/resendInviteForClientAdmin`, data);
}

const client = {
  createClient,
  getAllClients,
  logInAsClient,
  resendInviteForClientAdmin,
};

export default client;
