import { takeEvery, put } from 'redux-saga/effects';
import Api from '../../services/apis/index';
import loaderSlice from '../slices/loader.slice';
import floorSlice from '../slices/floor.slice';

function* getAllFloors(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.floor.getAllFloors(values);
    yield put(floorSlice.actions.setFloors(response.data.data));
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* floorSaga() {
  yield takeEvery('floor/getAllFloors', getAllFloors);
}

export default floorSaga;
