import { takeEvery, put } from 'redux-saga/effects';
import Api from '../../services/apis/index';
import loaderSlice from '../slices/loader.slice';
import departmentSlice from '../slices/department.slice';

function* getAllDepartments(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.department.getAllDepartments(values);
    yield put(departmentSlice.actions.setDepartment(response.data.data));
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* departmentSaga() {
  yield takeEvery('department/getAllDepartments', getAllDepartments);
}

export default departmentSaga;
