import React from 'react';
import './layout.scss';
import loginLeftIcon from '../../assets/images/icons/login-left-icon.svg';

const LoginLayout = ({ children }: any) => (
  <div className="flex w-full h-full items-center">
    <div className="max-w-5xl w-full mx-auto px-2 sm:px-6 lg:px-8">
      <div className="flex w-full shadow login-wrapper">
        <div className="hidden md:w-2/4 xl:w-5/12 py-3 px-12 md:flex flex-col bg-primary bg-primary-login justify-center items-center">
          <img className="w-40" src={loginLeftIcon} alt="" />
          {/* <p className="text-center font-normal text-white text-xs pt-3"></p> */}
        </div>
        <div className="w-full md:w-2/4 xl:w-7/12 p-3 flex-shrink-0">{children}</div>
      </div>
    </div>
  </div>
);

export default LoginLayout;
