/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type InitialState = {
  locations: any;
};

const initialState: InitialState = {
  locations: [],
};

const getAllLocations = (state: InitialState, action: any) => state;

const setLocationByClient = (state: InitialState, action: any) => {
  state.locations = action.payload;
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getAllLocations,
    setLocationByClient,
  },
});

export default locationSlice;
