/* eslint-disable object-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DevicePopup from '../../components/modals/create-device/device-popup';
import './devices.scss';
import Treeview from '../../components/treeview/treeview';
import AssignPopup from '../../components/modals/assign-device/assign-device';
import firstIcon from '../../assets/images/icons/first-icon.svg';
import prevIcon from '../../assets/images/icons/prev-icon.svg';
import lastIcon from '../../assets/images/icons/last-icon.svg';
import nextIcon from '../../assets/images/icons/next-icon.svg';
import { RootState } from '../../redux/store/rootReducer';
import deviceSlice from '../../redux/slices/device.slice';
import clientSlice from '../../redux/slices/client.slice';
import treeViewSlice from '../../redux/slices/treeView.slice';

const Devices = () => {
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [activeTab, setActiveTab] = useState('assigned');
  const [dropdownID, setDropdownID] = useState(null);
  const [dropDownName, setDropdownName] = useState('Select Client');
  const [openPop, setOpenPop] = useState(false);
  const [openPopSecond, setOpenPopSecond] = useState(false);
  const [doorId, setDoorId] = useState(false);
  const [searchTermAttached, setSearchTermAttached] = useState('');
  const [searchTermUnattached, setSearchTermUnattached] = useState('');
  const [pageNum, setpageNum] = useState(1);
  const [pageNumSecond, setpageNumSecond] = useState(1);
  const [clientSelect, setClientSelect] = useState('');

  const dropContainer = useRef<HTMLDivElement>(null);
  const dropContainerAssign = createRef<HTMLDivElement>();

  const dispatch = useDispatch();
  const UnattachedDeviceList = useSelector((state: RootState) => state.device.unattached_device_lists);
  const AttachedDeviceList = useSelector((state: RootState) => state.device.attached_device_lists);
  const AttachedDeviceCount: any = useSelector((state: RootState) => state.device.attached_device_list_count);
  const UnattachedDeviceCount: any = useSelector((state: RootState) => state.device.unattached_device_list_count);
  const getClientList = useSelector((state: RootState) => state.client.client_lists);

  useEffect(() => {
    if (filterDropdown === true) {
      const filterData = {
        limit: null,
        pageNo: null,
      };
      dispatch(clientSlice.actions.getAllClients(filterData));
    }
  }, [filterDropdown]);

  let filterData = {
    limit: 10,
    pageNo: 1,
    search: '',
    clientId: '',
  };

  const removeId = (device: any) => {
    const value = {
      deviceId: device,
    };
    dispatch(deviceSlice.actions.removeUnattachedDevices(value));
  };

  const attachedpagesCount = Math.ceil(AttachedDeviceCount / filterData.limit);
  const unattachedpagesCount = Math.ceil(UnattachedDeviceCount / filterData.limit);

  const attachedPageNums = [];
  for (let i = 0; i < attachedpagesCount; i += 1) {
    attachedPageNums.push(i);
  }
  const unattachedPageNums = [];
  for (let i = 0; i < unattachedpagesCount; i += 1) {
    unattachedPageNums.push(i);
  }

  useEffect(() => {
    filterData = {
      limit: 10,
      pageNo: pageNum,
      search: '',
      clientId: clientSelect,
    };
    dispatch(deviceSlice.actions.getAllAttachedDevices(filterData));
  }, [pageNum]);

  useEffect(() => {
    filterData = {
      limit: 10,
      pageNo: pageNum,
      search: '',
      clientId: clientSelect,
    };
    dispatch(deviceSlice.actions.getAllAttachedDevices(filterData));
  }, [clientSelect]);

  const handleChangeAttached = (e: any) => {
    setSearchTermAttached(e.target.value);
    filterData = {
      limit: 10,
      pageNo: 1,
      search: `${e.target.value}`,
      clientId: clientSelect,
    };
    dispatch(deviceSlice.actions.getAllAttachedDevices(filterData));
  };

  useEffect(() => {
    filterData = {
      limit: 10,
      pageNo: pageNumSecond,
      search: '',
      clientId: clientSelect,
    };
    dispatch(deviceSlice.actions.getAllUnattachedDevices(filterData));
  }, [pageNumSecond]);

  const handleChangeUnattached = (e: any) => {
    setSearchTermUnattached(e.target.value);
    filterData = {
      limit: 10,
      pageNo: 1,
      search: `${e.target.value}`,
      clientId: clientSelect,
    };
    dispatch(deviceSlice.actions.getAllUnattachedDevices(filterData));
  };

  const dropChange = (id: any) => {
    if (dropdownID !== id) {
      setDropdownID(id);
    } else {
      setDropdownID(null);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (!dropContainer?.current?.contains(event.target)) {
        if (!filterDropdown) return;
        setFilterDropdown(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [filterDropdown, dropContainer]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (!dropContainerAssign?.current?.contains(event.target)) {
        if (dropdownID === null) return;
        setDropdownID(null);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [dropdownID, dropContainerAssign]);

  const setKeys = (item: any) => {
    dispatch(treeViewSlice.actions.setKeys(item));
    dispatch(treeViewSlice.actions.setKeysPopUp(true));
  };

  return (
    <div className="w-full float-left pt-6">
      <div className="sidebar float-left">
        <div className="font-bold text-2xl text-black pb-2 flex justify-between items-center">
          <div>Devices</div>
        </div>
        <Treeview bg name="Clients" />
      </div>
      <div className="md:pl-6 location-block pb-4">
        <div className="w-full float-left device-tab-wrapper">
          <div className="w-full float-left border-b border-gray-300 font-medium">
            <div
              className={`device-tab-title text-base float-left pb-1 relative ${
                activeTab === 'assigned' ? 'active-tab' : ''
              }`}
              onClick={() => setActiveTab('assigned')}
            >
              Assigned Devices
            </div>
            <div
              className={`device-tab-title text-base float-left pb-1 relative ml-6 ${
                activeTab === 'un-assigned' ? 'active-tab' : ''
              }`}
              onClick={() => setActiveTab('un-assigned')}
            >
              Unassigned Devices
            </div>
          </div>
          <div className="w-full float-left device-tab-content">
            <div className={`w-full float-left assigned-device ${activeTab === 'assigned' ? '' : 'hidden'}`}>
              <div className="flex justify-end items-start filter-wrapper my-4">
                <div
                  ref={dropContainer}
                  className="filter-dropdown relative bg-white border border-gray-200 rounded shadow-xs"
                >
                  <div
                    onClick={() => setFilterDropdown(!filterDropdown)}
                    className="filter-dropdown-title relative text-xs font-medium truncate py-2 px-3 pr-6 cursor-pointer"
                  >
                    {dropDownName}
                  </div>
                  {filterDropdown && (
                    <ul className="filter-dropdown-ul w-full py-1 absolute right-0 bg-white border border-gray-200 shadow-md text-xs font-medium cursor-pointer">
                      {getClientList.map((client: any) => (
                        <li
                          onClick={() => {
                            setFilterDropdown(!filterDropdown);
                            setDropdownName(client.admin_name);
                            setClientSelect(client.id);
                          }}
                          className="px-2 py-1"
                        >
                          {client.admin_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="search-filter pl-3">
                  <input
                    className="px-3 text-xs border border-gray-200 rounded shadow-xs focus:outline-none"
                    type="text"
                    placeholder="Search"
                    value={searchTermAttached}
                    onChange={handleChangeAttached}
                  />
                </div>
              </div>
              <div className="device-table-wrapper w-full float-left overflow-auto rounded-xl">
                <table className="w-full">
                  <thead className="font-medium text-left">
                    <th>Device ID</th>
                    <th>Device</th>
                    <th>Connection Status</th>
                    <th />
                  </thead>
                  <tbody>
                    {AttachedDeviceList.length > 0 ? (
                      AttachedDeviceList.map((device: any) => (
                        <tr key={device.id}>
                          <td>{device.device_id}</td>
                          <td>{device.door_name}</td>
                          <td>{device.connection_state}</td>
                          <td>
                            <button
                              type="button"
                              onClick={() => setKeys(device)}
                              className="view-key border border-gray-400 text-xs py-1 px-2 rounded mr-3"
                            >
                              View key
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="device-table-wrapper w-full float-left overflow-auto rounded-xl" />
                    )}
                  </tbody>
                </table>
              </div>
              {attachedpagesCount > 1 && (
                <div className="w-full py-3 float-left pagination-wrappper flex justify-center">
                  <ul className="flex font-normal items-center">
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNum(1);
                        }}
                        href="#"
                      >
                        <img src={firstIcon} alt="First" />
                      </a>
                    </li>
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNum(pageNum - 1);
                        }}
                        href="#"
                      >
                        <img src={prevIcon} alt="Prev" />
                      </a>
                    </li>
                    {attachedPageNums.map((index) => (
                      <li className="active-page">
                        <a
                          onClick={() => {
                            setpageNum(index + 1);
                          }}
                          href="#"
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNum(pageNum + 1);
                        }}
                        href="#"
                      >
                        <img src={nextIcon} alt="Next" />
                      </a>
                    </li>
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNum(attachedpagesCount);
                        }}
                        href="#"
                      >
                        <img src={lastIcon} alt="Last" />
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className={`w-full float-left unassigned-device ${activeTab === 'un-assigned' ? '' : 'hidden'}`}>
              <div className="flex justify-end items-start filter-wrapper my-4">
                <button
                  onClick={() => setOpenPopSecond(true)}
                  className="btn-primary-bg focus:outline-none rounded px-6 text-white text-sm font-medium"
                >
                  Add Device
                </button>
                <div className="search-filter pl-3">
                  <input
                    className="px-3 text-xs border border-gray-200 rounded shadow-xs focus:outline-none"
                    type="text"
                    placeholder="Search"
                    value={searchTermUnattached}
                    onChange={handleChangeUnattached}
                  />
                </div>
              </div>
              <div className="device-table-wrapper w-full float-left overflow-auto rounded-xl">
                <table className="w-full">
                  <thead className="font-medium text-left">
                    <th>Device ID</th>
                    <th>Action</th>
                    <th />
                  </thead>
                  <tbody>
                    {UnattachedDeviceList.length > 0 ? (
                      UnattachedDeviceList.map((item: any, index: any) => (
                        <tr>
                          <td key={item.id}>{item.device_id}</td>
                          <td>
                            <div ref={dropContainerAssign} className="device-action-drop relative">
                              <div
                                onClick={() => {
                                  dropChange(index);
                                  setDoorId(item.id);
                                }}
                                className="device-action-drop-title font-normal text-sm"
                              >
                                Select Action
                              </div>
                              <ul
                                className={`action-dropdown-ul w-full py-1 absolute right-0 bg-white border border-gray-200 shadow-md text-xs font-medium cursor-pointer ${
                                  dropdownID === index ? '' : 'hidden'
                                }`}
                              >
                                <li onClick={() => setOpenPop(true)} className="px-2 py-1">
                                  Assign Device
                                </li>
                                <li onClick={() => removeId(item.device_id)} className="px-2 py-1">
                                  Remove Device
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={() => setKeys(item)}
                              className="view-key border border-gray-400 text-xs py-1 px-2 rounded mr-3"
                            >
                              View key
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="device-table-wrapper w-full float-left overflow-auto rounded-xl" />
                    )}
                  </tbody>
                </table>
              </div>
              <AssignPopup hasModalView={openPop} closeModal={() => setOpenPop(false)} doorId={doorId} />
              {unattachedpagesCount > 1 && (
                <div className="w-full py-3 float-left pagination-wrappper flex justify-center">
                  <ul className="flex font-normal items-center">
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNumSecond(1);
                        }}
                        href="#"
                      >
                        <img src={firstIcon} alt="First" />
                      </a>
                    </li>
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNumSecond(pageNumSecond - 1);
                        }}
                        href="#"
                      >
                        <img src={prevIcon} alt="Prev" />
                      </a>
                    </li>
                    {unattachedPageNums.map((index) => (
                      <li className="active-page">
                        <a
                          onClick={() => {
                            setpageNumSecond(index + 1);
                          }}
                          href="#"
                        >
                          {index + 1}
                        </a>
                      </li>
                    ))}
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNumSecond(pageNumSecond + 1);
                        }}
                        href="#"
                      >
                        <img src={nextIcon} alt="Next" />
                      </a>
                    </li>
                    <li className="has-bg">
                      <a
                        onClick={() => {
                          setpageNumSecond(unattachedpagesCount);
                        }}
                        href="#"
                      >
                        <img src={lastIcon} alt="Last" />
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DevicePopup hasModalView={openPopSecond} closeModal={() => setOpenPopSecond(false)} />
    </div>
  );
};
export default Devices;
