/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getAllDevicesInDoor(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getAllDevicesInDoor`, {
    params: {
      doorId: values,
    },
  });
}

function* attachDevice(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/door/updateDoorAdmin`, data);
}

function* createDevice() {
  return yield request.post(`${APP_ENTRYPOINT}/door/createDoor`);
}

function* removeUnattachedDevices(data: any) {
  return yield request.post(`${APP_ENTRYPOINT}/admin/removeUnattachedDevices`, data);
}

function* getAllUnattachedDevices(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getAllUnAssingedDevices`, {
    params: {
      limit: values.limit,
      pageNo: values.pageNo,
      search: values.search,
    },
  });
}

function* getAllAttachedDevices(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getAllAssingedDevices`, {
    params: {
      limit: values.limit,
      pageNo: values.pageNo,
      search: values.search,
      clientId: values.clientId,
    },
  });
}

function getSensorsOfDevice(deviceId: string) {
  return request.get(`${APP_ENTRYPOINT}/sensor/getSensorsOfDevice`, {
    params: {
      device_id: deviceId,
    },
  });
}

function getDevicesByClientId(clientId: string) {
  return request.get(`${APP_ENTRYPOINT}/door/getDevicesByClientId`, {
    params: {
      client_id: clientId,
    },
  });
}

function getAllDevices(values: any) {
  return request.get(`${APP_ENTRYPOINT}/admin/getAllDevices`, {
    params: values,
  });
}

const Device = {
  getAllDevicesInDoor,
  getAllUnattachedDevices,
  getAllAttachedDevices,
  attachDevice,
  createDevice,
  removeUnattachedDevices,
  getSensorsOfDevice,
  getDevicesByClientId,
  getAllDevices,
};

export default Device;
