/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getDashboardData() {
  return yield request.get(`${APP_ENTRYPOINT}/admin/dashboardData`);
}
function* getDashboardGraphData(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/dashboardGraphData`, {
    params: {
      ...values,
    },
  });
}
function getTimezone() {
  const date = new Date(); // Create a date object to get the current timezone offset.

  // Get the timezone offset in minutes.
  const timezoneOffsetMinutes = date.getTimezoneOffset();

  // Convert the minutes to hours and remainder minutes.
  const hours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60);
  const minutes = Math.abs(timezoneOffsetMinutes) % 60;

  // Determine the sign based on the offset.
  const sign = timezoneOffsetMinutes < 0 ? '+' : '-';

  // Format the offset as a string, e.g., '+05:30' or '-05:30'.
  const timezoneOffsetString = `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return timezoneOffsetString;
}

function getGraphData(values: any) {
  // get timezone in '+05:30' format
  const timezone = getTimezone();
  return request.get(`${APP_ENTRYPOINT}/admin/graphData`, {
    params: {
      ...values,
      timezone,
    },
  });
}

const dashboard = {
  getDashboardData,
  getDashboardGraphData,
  getGraphData,
};

export default dashboard;
