/* eslint-disable import/no-unresolved */
import { all } from 'redux-saga/effects';
import authSaga from '../sagas/auth.saga';
import clientSaga from '../sagas/client.saga';
import treeViewSaga from '../sagas/treeView.saga';
import adminUsersSaga from '../sagas/adminUsers.saga';
import dashboardSaga from '../sagas/dashboard.saga';
import device from '../sagas/device.saga';
import building from '../sagas/building.saga';
import department from '../sagas/department.saga';
import location from '../sagas/location.saga';
import floor from '../sagas/floor.saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    clientSaga(),
    treeViewSaga(),
    dashboardSaga(),
    adminUsersSaga(),
    device(),
    building(),
    department(),
    location(),
    floor(),
  ]);
}
