/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import { SketchPicker } from 'react-color';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../redux/store/rootReducer';
import { Client, FiltersInitialData } from '../../../types';
import deviceApis from '../../../services/apis/device/device';
import Api from '../../../services/apis';
import ComparisonChart from '../../../components/chart/comparison-chart';
import { getRandomColor } from '../../../utils';
import refreshIcon from '../../../assets/images/icons/refreshicon.png';
import buildingSlice from '../../../redux/slices/building.slice';
import departmentSlice from '../../../redux/slices/department.slice';
import locationSlice from '../../../redux/slices/location.slice';
import deviceSlice from '../../../redux/slices/device.slice';
import floorSlice from '../../../redux/slices/floor.slice';

interface graphDataType {
  // eslint-disable-next-line camelcase
  dataset: { trigger_count: number; hour: 'string' }[];
  filters: FiltersInitialData;
}
// need to compare the data graph with 2 devices at any date
// so we need to duplicate all the filters and query the api with 2 devices
const TriggerEvents24HourCompare = () => {
  const [devicesFiltersState, setDevicesFiltersState] = useState([] as FiltersInitialData[]);
  const filtersInitialData: FiltersInitialData = {
    format: '24hour',
    client: '',
    deviceId: '',
    sensorId: '',
    locationId: '',
    buildingId: '',
    departmentId: '',
    floorId: '',
    fromDate: format(new Date(new Date().setDate(new Date().getDate() - 7)), 'yyyy-MM-dd'),
    toDate: format(new Date(), 'yyyy-MM-dd'),
    color: devicesFiltersState.length === 0 ? '#00a49f' : getRandomColor(),
    isColorPickerOpen: false,
  };
  const title = 'Compare Trigger events - 24 hour view';
  // i need an option to add more devices to compare
  // eslint-disable-next-line max-len

  const [devicesOfClient, setDevicesOfClient] = useState({} as any);
  // const [filters, setFilters] = useState(filtersInitialData);
  const [sensorsOfDevice, setSensorsOfDevice] = useState({} as any);
  const [graphData, setGraphData] = useState([] as any);
  const [loadingGraph, setLoadingGraph] = useState(true);

  useEffect(() => {
    // getgraph data from api
    addNewComparison();
  }, []);

  const addNewComparison = () => {
    const newDevicesFiltersState = [...devicesFiltersState];
    newDevicesFiltersState.push(filtersInitialData);
    setDevicesFiltersState(newDevicesFiltersState);
    fetchGraphData(filtersInitialData, newDevicesFiltersState.length - 1);
    getAllDevices(newDevicesFiltersState.length - 1);
    getAllLocations('');
    getAllBuildings('');
    getAllFloor('');
    getAllDepartments('');
  };

  const removeCompare = (index: number) => {
    if (devicesFiltersState.length === 1) return;
    const newDevicesFiltersState = [...devicesFiltersState];
    newDevicesFiltersState.splice(index, 1);
    setDevicesFiltersState(newDevicesFiltersState);

    const newGraphdata = [...graphData];
    newGraphdata.splice(index, 1);
    setGraphData(newGraphdata);
  };

  const fetchGraphData = (newFilters: any, index: number) => {
    Api.dashboard
      .getGraphData(newFilters)
      .then((res: any) => {
        const newGraphdata = [...graphData];
        newGraphdata[index] = { dataset: res.data.data, filters: newFilters };
        setGraphData(newGraphdata);
        setLoadingGraph(false);
      })
      .catch((err: any) => {
        setLoadingGraph(false);

        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const refreshGraph = async () => {
    const promises: Promise<AxiosResponse>[] = [];
    setLoadingGraph(true);

    devicesFiltersState.forEach((filters, index) => {
      promises.push(Api.dashboard.getGraphData(filters));
    });
    try {
      const results = await Promise.allSettled(promises);
      const newGraphdata: graphDataType[] = [];
      // eslint-disable-next-line no-console
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          const { data } = result.value.data;
          newGraphdata.push({ dataset: data, filters: devicesFiltersState[index] });
        }
      });
      setGraphData(newGraphdata);
      setLoadingGraph(false);
    } catch (error) {
      setLoadingGraph(false);
    }
  };
  const getClientList = useSelector((state: RootState) => state.client.client_lists);
  const locationList = useSelector((state: RootState) => state.location.locations);
  const buildingList = useSelector((state: RootState) => state.building.buildings);
  const departmentList = useSelector((state: RootState) => state.department.departments);
  const deviceList = useSelector((state: RootState) => state.device.devices);
  const floorList = useSelector((state: RootState) => state.floor.floors);

  const handleClientSelect = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      client: e.target.value,
      deviceId: '',
      sensorId: '',
      locationId: '',
      clientId: e.target.value,
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
    if (e.target.value === '') {
      getAllDevices(index);
      getAllLocations('');
      getAllBuildings('');
      getAllFloor('');
      getAllDepartments('');
      const newSensorsOfdevice = { ...sensorsOfDevice };
      newSensorsOfdevice[index] = [];
      setSensorsOfDevice(sensorsOfDevice);
      return;
    }
    getAllDevices(newFilters);
    getAllLocations(e.target.value);
    getAllBuildings(newFilters);
    getAllFloor(newFilters);
    getAllDepartments(newFilters);
  };

  const handleLocationSelect = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      locationId: e.target.value,
      buildingId: '',
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
    if (e.target.value === '') {
      getAllBuildings(newFilters);
      getAllFloor(newFilters);
      getAllDepartments(newFilters);
      getAllDevices(newFilters);
      setSensorsOfDevice([]);
      return;
    }
    getAllDepartments(newFilters);
    getAllBuildings(newFilters);
    getAllFloor(newFilters);
    getAllDevices(newFilters);
  };

  const handleBuildingSelect = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      buildingId: e.target.value,
    };

    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
    if (e.target.value === '') {
      getAllFloor(newFilters);
      getAllDepartments(newFilters);
      getAllDevices(newFilters);
      setSensorsOfDevice([]);
      return;
    }
    getAllFloor(newFilters);
    getAllDepartments(newFilters);
    getAllDevices(newFilters);
  };

  const handleFloorSelect = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      floorId: e.target.value,
    };

    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
    if (e.target.value === '') {
      getAllDepartments(newFilters);
      getAllDevices(newFilters);
      setSensorsOfDevice([]);
      return;
    }

    getAllDepartments(newFilters);
    getAllDevices(newFilters);
  };

  const handleDepartmentSelect = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      departmentId: e.target.value,
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
    if (e.target.value === '') {
      getAllDevices(newFilters);
      setSensorsOfDevice([]);
      return;
    }
    getAllBuildings(e.target.value);
    getAllFloor(newFilters);
    getAllDevices(newFilters);
  };

  const handleDeviceSelect = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      deviceId: e.target.value,
      sensorId: '',
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
    if (e.target.value === '') {
      setSensorsOfDevice([]);
      return;
    }
    getSensorsOfDevice(e.target.value, index);
  };

  const handleSensorSelect = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      sensorId: e.target.value,
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
  };

  const handleToDateChange = (e: any, index: number) => {
    if (e.target.value < devicesFiltersState[index].fromDate) {
      return;
    }
    const newFilters = {
      ...devicesFiltersState[index],
      toDate: e.target.value,
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
  };

  const handleFromDateChange = (e: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      fromDate: e.target.value,
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    fetchGraphData(newFilters, index);
  };

  const handleIndexColorChange = (color: any, index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      color: color.hex,
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
    const newGraphdata = [...graphData];
    newGraphdata[index].filters = { ...newFilters };
    setGraphData(newGraphdata);
  };
  const getDevicesOfClient = (clientId: string, index: number) => {
    deviceApis
      .getDevicesByClientId(clientId)
      .then((res: any) => {
        setDevicesOfClient((current: any) => ({ ...current, [index]: res.data.data }));
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const getAllDevices = (value: any) => {
    dispatch(deviceSlice.actions.getAllDevices(value));
  };

  const getSensorsOfDevice = (deviceId: string, index: number) => {
    deviceApis
      .getSensorsOfDevice(deviceId)
      .then((res: any) => {
        // eslint-disable-next-line no-console
        // console.log(res);
        setSensorsOfDevice((current: any) => ({ ...current, [index]: res.data.data }));
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const dispatch = useDispatch();
  const getAllLocations = (clientId: string) => {
    const data = {
      id: clientId,
    };

    dispatch(locationSlice.actions.getAllLocations(data));
  };

  const getAllBuildings = (values: any) => {
    dispatch(buildingSlice.actions.getAllBuildings(values));
  };

  const getAllFloor = (values: any) => {
    dispatch(floorSlice.actions.getAllFloors(values));
  };

  const getAllDepartments = (filter: any) => {
    dispatch(departmentSlice.actions.getAllDepartments(filter));
  };

  const openColorPicker = (index: number) => {
    const newFilters = {
      ...devicesFiltersState[index],
      isColorPickerOpen: !devicesFiltersState[index].isColorPickerOpen,
    };
    devicesFiltersState[index] = newFilters;
    setDevicesFiltersState([...devicesFiltersState]);
  };
  return (
    <>
      {/* graphs section  */}
      <div className=" border-t-2 pt-4 border-gray-300 w-contain  lg:pl-2 order-0 lg:order-1 my-3 md:my-0">
        <h3 className="text-2xl font-bold text-color-dark mb-3">Compare Trigger Events - 24 hour</h3>
        <div className="flex flex-col graph-wrapper gap-4 ">
          {devicesFiltersState?.map((filters, index) => (
            <div key={filters.color} className="flex gap-4 gap-y-0 flex-wrap items-center w-full  md:justify-start">
              {/* color */}
              <div>
                <div className="uppercase font-medium text-sm graph-title mr-3">{index}</div>
                <div className="day-select-list relative cursor-pointer background-white border-2  border-gray-100">
                  <div
                    className="w-8 h-4 border-2 p-2"
                    style={{ backgroundColor: `${filters.color}57` }}
                    onClick={() => openColorPicker(index)}
                  />
                  {filters.isColorPickerOpen ? (
                    <div className="absolute z-10">
                      <div className="fixed top-0 right-0 left-0 bottom-0" onClick={() => openColorPicker(index)} />
                      <SketchPicker
                        color={filters.color}
                        onChangeComplete={(color) => handleIndexColorChange(color, index)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {/* </div> */}

              {/* date selection  */}
              <div>
                <div className="uppercase font-medium text-sm graph-title mr-3">FROM</div>
                <div className="day-select-list relative cursor-pointer">
                  <input
                    onChange={(e) => handleFromDateChange(e, index)}
                    value={filters.fromDate}
                    type="date"
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  />
                </div>
              </div>

              <div>
                <div className="uppercase font-medium text-sm graph-title mr-3">To</div>
                <div className="day-select-list relative cursor-pointer">
                  <input
                    onChange={(e) => handleToDateChange(e, index)}
                    value={filters.toDate}
                    min={filters.fromDate}
                    type="date"
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  />
                </div>
              </div>

              {/* filter by client id */}
              <div className="">
                <div className="uppercase font-medium text-sm graph-title ">By Client</div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={filters.client}
                    onChange={(e) => handleClientSelect(e, index)}
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      key={`client${index}all`}
                      className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value=""
                    >
                      All
                    </option>
                    <option className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list " value="null">
                      Unassigned
                    </option>
                    {getClientList.map((client: Client) => (
                      <option
                        key={client.id}
                        className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                        value={client.id}
                      >
                        {client.admin_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* by location */}
              <div className="">
                <div className="uppercase font-medium text-sm graph-title ">By location</div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={filters.locationId}
                    onChange={(e) => handleLocationSelect(e, index)}
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      key={`location${index}all`}
                      className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value=""
                    >
                      All
                    </option>
                    {locationList?.map((location: any) => (
                      <option
                        key={location.id}
                        className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                        value={location.id}
                      >
                        {location.location_name || location.id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* by building */}
              <div className="">
                <div className="uppercase font-medium text-sm graph-title ">By building</div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={filters.buildingId}
                    onChange={(e) => handleBuildingSelect(e, index)}
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      key={`location${index}all`}
                      className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value=""
                    >
                      All
                    </option>
                    {buildingList?.map((building: any) => (
                      <option
                        key={building.id}
                        className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                        value={building.id}
                      >
                        {building.building_name || building.id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* by floor */}
              <div className="">
                <div className="uppercase font-medium text-sm graph-title ">By floor</div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={filters.floorId}
                    onChange={(e) => handleFloorSelect(e, index)}
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      key={`location${index}all`}
                      className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value=""
                    >
                      All
                    </option>
                    {floorList?.map((floor: any) => (
                      <option
                        key={floor.id}
                        className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                        value={floor.id}
                      >
                        {floor.floor_name || floor.id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* by department */}
              <div className="">
                <div className="uppercase font-medium text-sm graph-title ">By department</div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={filters.departmentId}
                    onChange={(e) => handleDepartmentSelect(e, index)}
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      key={`location${index}all`}
                      className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value=""
                    >
                      All
                    </option>
                    {departmentList?.map((department: any) => (
                      <option
                        key={department.id}
                        className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                        value={department.id}
                      >
                        {department.department_name || department.id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* by device */}
              <div className="">
                <div className="uppercase font-medium text-sm graph-title ">By device</div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={filters.deviceId}
                    onChange={(e) => handleDeviceSelect(e, index)}
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      key={`device${index}all`}
                      className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value=""
                    >
                      All
                    </option>
                    {deviceList?.map((device: any) => (
                      <option
                        key={device.device_id}
                        className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                        value={device.device_id}
                      >
                        {device.door_name || device.device_id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* by sensor of device */}
              <div className="">
                <div className="uppercase font-medium text-sm graph-title ">By sensor</div>
                <div className="day-select-list relative cursor-pointer">
                  <select
                    value={filters.sensorId}
                    onChange={(e) => handleSensorSelect(e, index)}
                    className="bg-gray-200 border-2 border-gray-300 py-1 pl-2 relative pr-8 data-selected-val text-xs font-medium"
                  >
                    <option
                      key={`sensor${index}all`}
                      className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                      value=""
                    >
                      All
                    </option>
                    {sensorsOfDevice[index]?.map((sensor: any) => (
                      <option
                        key={sensor.id}
                        className="w-full  absolute left-0 py-2 shadow-md border-t dropdown-list "
                        value={sensor.sensor_id}
                      >
                        {sensor.sensor_name || sensor.sensor_id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {devicesFiltersState.length > 1 && (
                <div className="">
                  <div className="uppercase font-medium text-sm graph-title opacity-0"> Options</div>

                  <button
                    className="max-w-max border border-gray-400 text-xs py-1 px-2 rounded mr-3"
                    type="button"
                    onClick={() => {
                      removeCompare(index);
                    }}
                  >
                    Remove this comparison
                  </button>
                </div>
              )}
            </div>
          ))}
          <button
            className="max-w-max border border-gray-400 text-xs py-1 px-2 rounded mr-3"
            type="button"
            onClick={addNewComparison}
          >
            Add new comparison
          </button>
          {/* graph */}
          <div className="lg:w-2/3 w-full rounded-lg mt-3 px-3 pb-3 pt-2 graph-container relative">
            <button
              className="max-w-max border border-gray-400 text-xs py-1 px-2 rounded absolute right-1 "
              type="button"
              disabled={loadingGraph}
              onClick={refreshGraph}
            >
              <img className={`w-4 inline ${loadingGraph ? 'animate-spin' : ''}`} src={refreshIcon} alt="" />
            </button>
            <ComparisonChart data={graphData} />
          </div>
        </div>
      </div>
    </>
  );
};
export default TriggerEvents24HourCompare;
