/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { request } from '../../request/request';
// import { any } from 'prop-types';

function* getAllFloorsInBuilding(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/floor/getAllFloorsInBuilding`, {
    params: {
      buildingId: values,
    },
  });
}
function* getFloorById(values: any) {
  return yield request.get(`${APP_ENTRYPOINT}/admin/getClientFloorById`, {
    params: {
      floorId: values,
    },
  });
}

function getAllFloors(values: any) {
  return request.get(`${APP_ENTRYPOINT}/admin/getAllFloors`, {
    params: values,
  });
}

const department = {
  getAllFloorsInBuilding,
  getFloorById,
  getAllFloors,
};

export default department;
