import { takeEvery, put } from 'redux-saga/effects';
import Api from '../../services/apis/index';
import loaderSlice from '../slices/loader.slice';
import locationSlice from '../slices/location.slice';

function* getAllLocations(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.location.getAllLocations(values.id);
    yield put(locationSlice.actions.setLocationByClient(response.data.data));
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* locationSaga() {
  yield takeEvery('location/getAllLocations', getAllLocations);
}

export default locationSaga;
