/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react';
import './header.scss';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/icons/logo.svg';
import userIcon from '../../assets/images/icons/user-icon.svg';

import toggleIcon from '../../assets/images/icons/mob-menu-toggle.svg';
import authSlice from '../../redux/slices/auth.slice';
import Navlinks from './navlinks';

const Header = (props: any) => {
  const [proflieDropdown, setProfileDropdown] = useState(false);
  // const [showactive, setshowactive] = useState(false);
  const [responsiveMenu, setResponsiveMenu] = useState(false);

  const dropContainer = useRef<HTMLDivElement>(null);
  const pathName = window.location.pathname;

  if (pathName === '/dashboard' || pathName === '/dashboard/home') {
    document.body.classList.remove('child-bg');
  } else {
    document.body.classList.add('child-bg');
  }
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(authSlice.actions.logout());
  };
  useEffect(() => {
    dispatch(authSlice.actions.getcurrentAdminuser('hai'));

    const handleOutsideClick = (event: any) => {
      if (!dropContainer?.current?.contains(event.target)) {
        if (!proflieDropdown) return;
        setProfileDropdown(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [proflieDropdown, dropContainer]);
  const currentAdminuser = useSelector(
    (state: {
      auth: {
        user: any;
      };
    }) => state.auth.user
  );
  return (
    <div className="header fixed w-full z-50">
      <nav className="bg-white">
        <div className={`${props.container === 'fluid' ? 'w-full' : 'max-w-7xl mx-auto'} px-2 sm:px-6 lg:px-8`}>
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
              <button
                onClick={() => setResponsiveMenu(!responsiveMenu)}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 focus:outline-none"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <img src={toggleIcon} alt="" />
              </button>
            </div>
            <a className="flex items-center text-black text-lg font-bold flex-shrink-0 whitespace-nowrap ml-10 md:ml-0">
              <img className="mr-2 w-6" src={logo} alt="logo" />
              Hygotech
            </a>
            <div className="hidden md:flex mx-auto items-center">
              <Navlinks />
            </div>

            <div className="flex ml-auto md:ml-0 items-center">
              <div className="flex items-center">
                <p className="pl-4 mr-2 hidden sm:block font-medium text-sm text-color-gray hidden">
                  Logged in as : {currentAdminuser?.name || 'no name'}
                </p>
              </div>
              <div ref={dropContainer} className="relative">
                <button
                  onClick={() => setProfileDropdown(!proflieDropdown)}
                  className="flex text-sm focus:outline-none"
                  id="user-menu"
                  aria-haspopup="true"
                >
                  <span className="sr-only">Open user menu</span>
                  <img className="h-12 w-12" src={userIcon} alt="" />
                </button>
                <div
                  className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 ${
                    !proflieDropdown ? 'hidden' : ''
                  }`}
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <ul className="cursor-pointer">
                    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                      <span className="text-gray-110">Your Profile</span>
                    </li>
                    <li className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                      <span className="text-gray-110">Settings</span>
                    </li>
                    <li
                      onClick={logout}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                    >
                      <span className="text-gray-110">Sign&nbsp;Out</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`md:hidden w-full ${responsiveMenu ? 'flex' : 'hidden'}`}>
          <Navlinks />
        </div>
      </nav>
    </div>
  );
};

export default Header;
