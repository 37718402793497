/* eslint-disable max-len */
/* eslint-disable indent */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import differenceInDays from 'date-fns/differenceInDays';

import { FiltersInitialData } from '../../types';

// eslint-disable-next-line object-curly-newline
const ComparisonChartForDates = ({
  data,
}: {
  // eslint-disable-next-line camelcase
  data: [{ dataset: [{ date: string; trigger_count: number }]; filters: FiltersInitialData }];
}) => {
  const graphData = {
    labels: [] as any,
    datasets: [] as any,
  };

  const today = new Date();
  let leastDate = today;
  let maxDate = new Date(1970, 0, 1);
  let isFilteringBylastDays = false;

  data.forEach((dItem) => {
    const { fromDate, days, toDate } = dItem.filters;
    // Check if the filter is using the "last n days" condition.
    if (days) {
      isFilteringBylastDays = true;
      maxDate = today;
      // Calculate the left date by subtracting 'days' from the current date.

      const leftDate = new Date(new Date().setDate(new Date().getDate() - days));
      if (leftDate < leastDate) {
        leastDate = leftDate;
      }
    } else if (fromDate) {
      // If not "last n days," check for 'fromDate' and 'toDate' filters.
      const from = new Date(fromDate);
      const to = new Date(toDate);
      if (from < leastDate) {
        leastDate = from;
      }
      // If not filtering by "last n days" and 'toDate' is later than 'maxDate,' update 'maxDate.'

      if (!isFilteringBylastDays && to > maxDate) {
        maxDate = to;
      }
    }
  });

  data.forEach((dItem, key) => {
    const dataItem = JSON.parse(JSON.stringify(dItem));
    if (dataItem.dataset && dataItem.dataset.length) {
      const numberOfdays = differenceInDays(maxDate, leastDate);
      // data may not have all the days, so we need to fill the missing days with 0
      const daysStrings = [...Array(numberOfdays)].map((_, i) => {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        return date.toDateString().slice(4);
      });
      const dataDays = dataItem.dataset.map((obj: any) => new Date(obj.date).toDateString().slice(4));
      const missingDays = daysStrings.filter((day) => !dataDays.includes(day));
      const missingDaysData = missingDays.map((day) => ({
        date: day,
        trigger_count: 0,
      }));
      // eslint-disable-next-line no-param-reassign
      // @ts-ignore
      dataItem.dataset = [...dataItem.dataset, ...missingDaysData];
      // sort by date
      dataItem.dataset.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

      // date string without year
      dataItem.dataset.forEach((obj: any) => {
        const label = new Date(obj.date).toDateString().slice(4);
        if (!graphData.labels.includes(label)) {
          graphData.labels.push(label);
        }
      });
      const triggerCount = dataItem.dataset.map((obj: any) => obj.door_count || obj.trigger_count);

      graphData.datasets.push({
        label: dataItem.filters.deviceId
          ? `${key}. ${dataItem.filters.deviceId} Sensor ${
              dataItem.filters.sensorId ? dataItem.filters.sensorId : 'All'
            }`
          : `${key}. ${
              dataItem.filters.fromDate
                ? `${dataItem.filters.fromDate} to ${dataItem.filters.toDate}`
                : `last ${dataItem.filters.days} days`
            }`,
        backgroundColor: `${dataItem.filters.color}57`,
        borderColor: `${dataItem.filters.color}`,
        borderWidth: 0,
        hoverBackgroundColor: `${dataItem.filters.color}`,
        hoverBorderColor: `${dataItem.filters.color}`,
        pointBackgroundColor: `${dataItem.filters.color}`,
        pointBorderColor: '#fff',
        data: triggerCount,
      });
    }
    // }
  });

  return (
    <>
      {!Object.keys(data).length && (
        <div className="absolute bg-gray-200 m-auto top-0 bottom-0 left-0 right-0 text-center align-middle max-h-max">
          No data available
        </div>
      )}
      <Bar
        data={graphData}
        // width={480}
        // height={360}
        options={{
          maintainAspectRatio: true,
          responsive: true,
          legend: {
            display: true,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontSize: 12,
                  fontColor: '#6F6C99',
                  padding: 10,
                  precision: 0,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 12,
                  fontColor: '#6F6C99',
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }}
      />
    </>
  );
};

export default ComparisonChartForDates;
