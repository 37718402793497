import { takeEvery, put } from 'redux-saga/effects';
import Api from '../../services/apis/index';
import loaderSlice from '../slices/loader.slice';
import buildingSlice from '../slices/building.slice';

function* getAllBuildings(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const values: any = action.payload;
    const response = yield Api.building.getAllBuildings(values);
    yield put(buildingSlice.actions.setBuildingsByLocation(response.data.data));
    yield put(loaderSlice.actions.hide());
  } catch (err) {
    yield put(loaderSlice.actions.hide());
  }
}

function* buildingSaga() {
  yield takeEvery('building/getAllBuildings', getAllBuildings);
}

export default buildingSaga;
