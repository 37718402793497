/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import deviceSlice from '../../../redux/slices/device.slice';
import './device-popup.scss';
import closeIcon from '../../../assets/images/icons/close-icon.svg';
// import { createDeviceFormSchema } from '../../../services/validation';

const initialValues = {
  device_id: '',
};
const DevicePopup = ({ hasModalView, closeModal }: any) => {
  const formikRef: any = useRef();
  const dispatch = useDispatch();
  const onSubmit = (values: typeof initialValues) => {
    dispatch(deviceSlice.actions.createDevice(values));
    closeModal();
  };
  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <div>
          <div
            className={`popup-wrapper fixed w-full h-full top-0 left-0 overflow-auto ${
              hasModalView ? 'block' : 'hidden'
            }`}
          >
            <div className="popup-container absolute w-full mb-6 top-6 md:mb-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2">
              <div className="w-full float-left bg-white px-5 pt-8 pb-6 rounded-md relative overflow-hidden">
                <div onClick={() => closeModal()} className="popup-close absolute top-6 right-5 cursor-pointer">
                  <img src={closeIcon} />
                </div>
                <div className="popup-title font-bold text-2xl">Create Device</div>
                <div className="popup-frm w-full float-left pt-4 pb-2">
                  <div className="flex flex-col mb-3">
                    {/* <label className="font-medium text-sm">Device Name</label> */}
                    {/* <input
                      className="w-full px-4 h-14 border-gray border border-gray-300 rounded-lg focus:outline-none"
                      placeholder="Enter device Name"
                      type="text"
                      name="device_id"
                      value={values.device_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {errors.device_id && touched.device_id && <div>{errors.device_id}</div>} */}
                    <p>Are you sure to create a new device</p>
                  </div>

                  <div className="popup-btn flex w-full justify-end mt-5">
                    <button
                      className="rounded-full py-2 px-4 font-semibold text-sm text-white h-11 bg-primary focus:outline-none"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default DevicePopup;
