/* eslint-disable indent */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { FiltersInitialData } from '../../types';

// eslint-disable-next-line object-curly-newline
const ComparisonChart = ({
  data,
}: {
  // eslint-disable-next-line camelcase
  data: [{ dataset: [{ hour: string; trigger_count: number }]; filters: FiltersInitialData }];
}) => {
  const labels = [
    '00-01',
    '01-02',
    '02-03',
    '03-04',
    '04-05',
    '05-06',
    '06-07',
    '07-08',
    '08-09',
    '09-10',
    '10-11',
    '11-12',
    '12-13',
    '13-14',
    '14-15',
    '15-16',
    '16-17',
    '17-18',
    '18-19',
    '19-20',
    '20-21',
    '21-22',
    '22-23',
    '23-00',
  ];

  const graphData = {
    labels,
    datasets: [] as any,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const dataItem = data[key];
      const triggerCount = labels.map(
        (label: any) =>
          // eslint-disable-next-line comma-dangle, implicit-arrow-linebreak, max-len
          dataItem.dataset.find((obj: any) => obj.hour.slice(0, 2) === label.slice(0, 2))?.trigger_count || 0
      );

      graphData.datasets.push({
        label: dataItem.filters.deviceId
          ? `${key}. ${dataItem.filters.deviceId} Sensor ${
              dataItem.filters.sensorId ? dataItem.filters.sensorId : 'All'
            }`
          : `${key}. ${dataItem.filters.fromDate} to ${dataItem.filters.toDate}`,
        backgroundColor: `${dataItem.filters.color}57`,
        borderColor: `${dataItem.filters.color}`,
        borderWidth: 0,
        hoverBackgroundColor: `${dataItem.filters.color}`,
        hoverBorderColor: `${dataItem.filters.color}`,
        pointBackgroundColor: `${dataItem.filters.color}`,
        pointBorderColor: '#fff',
        data: triggerCount,
      });
    }
  }

  return (
    <>
      {!Object.keys(data).length && (
        <div className="absolute bg-gray-200 m-auto top-0 bottom-0 left-0 right-0 text-center align-middle max-h-max">
          No data available
        </div>
      )}
      <Bar
        data={graphData}
        // width={480}
        // height={360}
        options={{
          maintainAspectRatio: true,
          responsive: true,
          legend: {
            display: true,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontSize: 12,
                  fontColor: '#6F6C99',
                  padding: 10,
                  precision: 0,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: 12,
                  fontColor: '#6F6C99',
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
        }}
      />
    </>
  );
};

export default ComparisonChart;
