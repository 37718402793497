/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './dashboard.scss';
import Treeview from '../../components/treeview/treeview';
import groupIcon from '../../assets/images/icons/group.svg';
import doorConnectedIcon from '../../assets/images/icons/door-connected.svg';
import deviceIcon from '../../assets/images/icons/devices.svg';
import notificationIcon from '../../assets/images/icons/notification-icon.svg';
import { RootState } from '../../redux/store/rootReducer';
import dashboardSlice from '../../redux/slices/dashboard.slice';
import clientSlice from '../../redux/slices/client.slice';
import TriggerEvents24HourCompare from './charts/trigger-events-24-hour-compare';
import TriggerEventsCompare from './charts/trigger-events-compare';

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardInfo = useSelector((state: RootState) => state.dashboardSlice.dashboardInfo);

  useEffect(() => {
    dispatch(dashboardSlice.actions.getDashboardInfo());
    dispatch(clientSlice.actions.getAllClients({ limit: null, pageNo: null }));
  }, []);

  return (
    <div className="flex flex-col md:flex-row pt-6 min-h-full relative">
      <div className="sidebar-wrapper min-h-full pt-4  px-2 sm:px-6 md:px-0 md:pl-6 lg:pl-8 w-full md:w-96 flex-shrink-0">
        <div className="sidebar flex-shrink-0 px-4 md:px-0 rounded-md md:rounded-0">
          <Treeview bg={false} name="Clients" />
        </div>
      </div>
      <div className="w-contain m-4 flex flex-col gap-4">
        <div className="w-full flex gap-4  flex-wrap md:pr-2 order-1 lg:order-0 my-3 md:my-5 lg:my-0">
          <div className="flex  md:w-max w-60 justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
            <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
              <img className="mr-2" src={groupIcon} />
              <div className="truncate text-color-dark">Clients</div>
            </div>
            <div className="text-xl font-medium bg-yellow-100 px-3 py-2 ml-2 rounded">{dashboardInfo.clientsCount}</div>
          </div>
          <div className="flex  md:w-max w-60 justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
            <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
              <img className="mr-2" src={doorConnectedIcon} />
              <div className="truncate text-color-dark">Devices Connected</div>
            </div>
            <div className="text-xl font-medium bg-blue-100 px-3 py-2 ml-2 rounded">{dashboardInfo.doorCount}</div>
          </div>
          <div className="flex  md:w-max w-60 justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
            <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
              <img className="mr-2" src={deviceIcon} />
              <div className="truncate text-color-dark">Unassigned devices</div>
            </div>
            <div className="text-xl font-medium bg-blue-50 px-3 py-2 ml-2 rounded">
              {dashboardInfo.unassignedDoorCount}
            </div>
          </div>
          <div className="flex  md:w-max w-60 justify-between rounded-lg items-center px-4 py-3 mb-3 info-box">
            <div className="uppercase font-medium text-sm overflow-hidden flex items-center">
              <img className="mr-2" src={notificationIcon} />
              <div className="truncate text-color-dark">Buildings</div>
            </div>
            <div className="text-xl font-medium bg-pink-100 px-3 py-2 ml-2 rounded">{dashboardInfo.buildingCount}</div>
          </div>
        </div>
        <TriggerEventsCompare />

        {/* <TriggerEvents24Hour /> */}

        <TriggerEvents24HourCompare />
      </div>
    </div>
  );
};

export default Dashboard;
